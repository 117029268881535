import { Box, Button, Container, Stack, Typography } from "@mui/material";

interface HeroProps {
  setFormIsOpen: (value: boolean) => void;
}

const Hero = ({ setFormIsOpen }: HeroProps) => {
  return <Box sx={{backgroundColor: "#010101"}} >
    <Container maxWidth="xl" sx={{overflow: "hidden", height: { xs: 530, sm: 585, md: 1180 }, pb: "5vh" }}>
      <Box pt={{ xs: "120px", md: "180px"}} sx={{display: "flex", justifyContent: "center",
       "& img":{width: {xs: "180%", sm: "100%", md: "180%", lg: "100%"}, height: {xs: 253, sm: "100%"}}}} position="relative" >
        <img src={"/images/trader-files/team.png"} alt={"team"} />
        <Stack position="absolute" top={{xs: 249, sm: 268, md: 450}} alignItems="center" width="100%" >
          <Typography 
          sx={{textShadow: "2px 1px 1px rgb(88 88 88), -1px 0px 1px rgb(240 240 240)"}}
          fontSize={{xs: 121, md: 277}} fontWeight={900} lineHeight={{xs: "100px", md: "250px"}} color="#fff">13</Typography>
          <Typography fontSize={{xs: 27, md: 60}} fontWeight="bold" lineHeight={{xs: "26px", md: "65px"}} textAlign="center" color="#fff">файлов <br /> для  трейдера</Typography>
          <Typography fontSize={{xs: 13, md: 28}} fontWeight="200" mt={{xs: 2, md: 4}} textAlign="center" color="#fff">
            от ТОПовой команды, которые сэкономят тебе <br />
            месяцы работы и кучу слитых денег!
          </Typography>
          <Box display="flex" justifyContent="center" mt={{xs: 2, md: 6}}  >
            <Button onClick={() => {
               //@ts-ignore
               ym(96414864, 'reachGoal', 'button_form')
              setFormIsOpen(true)
            }} variant="contained" sx={{ textTransform: "none", borderRadius: 6, px: {xs: 3, md: 5}, fontSize: { xs: 11, md: 16 }, height: { xs: 33, md: 65 } }}>Забрать файлы бесплатно</Button>
          </Box>
        </Stack>
      </Box>

    </Container>
  </Box>
}


export default Hero;